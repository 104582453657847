<template>
    <div>
      <base-header class="pb-6">
        <b-row class="py-4">
          <b-col lg="6" cols="7">
            <h6 class="h2 text-white d-inline-block mb-0">Rincian Pendaftaran Air Limbah</h6>
          </b-col>
          <b-col lg="6" cols="7" style="display:flex;justify-content:flex-end">
                <base-button  @click="back()">Kembali</base-button>
          </b-col>
        </b-row>
      </base-header>

      <b-container v-if="isLoading">
        <h2 class="text-center" style="margin-top: 30vh;">Loading...</h2>
      </b-container>
  
      <b-container fluid class="mt--5" v-if="!isLoading">
        <b-row>
          
          <!-- umum -->
          <b-col xl="12">
            <b-card>
              <template v-slot:header>
                <div class="card-body">

                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Industri</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.comp_name }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Dibuat oleh</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.created_by }} - {{ formatDate(listData.created_at) }}</span>
                        </div>
                      </div>
                    </li>

                    <!-- value -->
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit air recovery</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_recovery.value }} {{ listData.debit_recovery.unit }} (<i>{{ listData.debit_recovery.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit air recycle</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_recycle.value}} {{ listData.debit_recycle.unit }} (<i>{{ listData.debit_recycle.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit air reuse</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_reuse.value }} {{ listData.debit_reuse.unit }} (<i>{{ listData.debit_reuse.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit Air Limbah Diolah IPAL</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_olah_ipal.value }} {{ listData.debit_olah_ipal.unit }} (<i>{{ listData.debit_olah_ipal.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit Air Limbah Total</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_limbah_total.value }} {{ listData.debit_limbah_total.unit }} (<i>{{ listData.debit_limbah_total.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit Penggunaan air</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_penggunaan.value }} {{ listData.debit_penggunaan.unit }} (<i>{{ listData.debit_penggunaan.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                   

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Unggah Neraca Air Dan Unggah Air Limbah</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="getBlobsFile(listData.water_and_was_water_balance_path)" target="blank">Lihat file</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </b-card>
          </b-col>
  

        </b-row>
      </b-container>

      <div class="row justify-content-end position-fixed right-0" style="top:50%">
          <base-button @click="validasiHandler" class="mx-3 mb-4" type="danger">
            <!-- {{ modalData.isValidated ? "Riwayat Validasi" : "Validasi" }} -->
            Validasi
          </base-button>
      </div>

      <ModalValidasi v-if="showModal" :data="[listRiwayat, listData]" :show-modal="showModal" @close="onCloseModal" />


      

    </div>
  </template>
  <script>
  import API from "../../../../api/base_url.js"
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import BaseHeader from "@/components/BaseHeader";
  import moment from 'moment'
  import Modal from "@/components/Modal.vue";
  import ModalValidasi from "../ModalValidasi.vue";
  import baseURL from "@/api/base_url_backend.js";

  
  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      Modal,
      ModalValidasi
    },
    data() {
      return {
        showModal:false,
        isLoading:true,
        listData: {}
      };
    },
    mounted() {
      this.getData()
      this.getRiwayat()
    },
    methods: {
      getBlobsFile(filename) {
        let url = `${baseURL}blobs/download/${filename}`
        return url
      },
      validasiHandler() {
        this.showModal = true;
      },
      onCloseModal() {
        this.showModal = false;
        this.getData();
        this.getRiwayat()
      },
      getRiwayat() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        console.log("CAKK");
        API.get(`companies/waste-uses/histories?waste_use_id=${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          if (content.data == null) {
          this.listRiwayat = []
          } else {
            this.listRiwayat = content.data
          }
        })
        .catch((err) => {
          console.log(err);
        })
      },
      formatDate(date) {
        return moment(date).format("DD/MM/YYYY");
      },
      getData() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`waste-recycles/${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          console.log(content.data,'uhuuuu');
          this.listData = content.data
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err);
        })
      },
      back() {
        this.$router.push("/pengolahan-kembali/air-limbah");
      },
    },
  };
  </script>
  <style scoped>

.mdl-contents {
  overflow-y: auto;
  max-height: 90%;
}
.mdl-header {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
}
.mdl-header i {
  font-size: 30px;
  color: #00bcd4;
  color: #FFFFFF;
}
.mdl-header p {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
}

.announcement-modal-backgrounds {
  background: #ff9999;
}

  </style>
  